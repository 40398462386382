import React from 'react';
import img1 from './assets/img/img1.jpg';
import img2 from './assets/img/img2.png';
import img3 from './assets/img/img3.jpg';
import img4 from './assets/img/img4.png';
import img5 from './assets/img/img5.jpg';
import img6 from './assets/img/img6.jpg';

export default function Team() {
  const teamMembers = [
    {
      img: img1,
      name: "Visitas domiciliarias",
      profession: "",
      info: [
        "Nuestros especialistas en medicina familiar, recibidos en Cuba siempre estan dispuestos para brindar una atencion de exelencia.",
        "Medicina familiar",
        "Podologia clinica",
        "Hospitalizacion domiciliaria",
        "Kinesioterapia respiratoria y musculoesqueletica",
      ],
    },
    {
      img: img2,
      name: "Consultas de salud mental",
      profession: "",
      info: [
        "Siempre creemos que uno de los pilares de la salud es la estabilidad mental y emocional, donde nuestros profesionales dupla Medico-Psicologo siempre dispuestos para atender, apoyar y brindar una atencion de primera",
        "Medico de salud mental",
        "Psicologo",
      ],
    },
    {
      img: img5,
      name: "Toma de muestras a domicilio",
      profession: "Laboratorio Clinico Ariztia",
      info: [
        "Indepencencia 422, local 4",
        "Cotice su atencion al Whatsapp  +56938800510 ",
        "Domicilios solo en Ovalle y alrrededores",
        "Nuestros profesionales de enfermeria y laboratorio clinico, siempre estan dispuestos para brindar una atencion de exelencia.",
      ], 
    },
    {
      img: img3,
      name: "Consultas de telemedicina",
      profession: "",
      info: [
        "Les brindamos una atencion de exelencia, para que sea atendido desde la comodidad de su casa a la distancia de un clic.",
        "Medicina general.",
        "Psicologia.",
        "Nutricion.",
      ],
    },
    {
      img: img6,
      name: "Kinesiologia",
      profession: "",
      info: [
        "No hay salud completa si tu cuerpo no esta bien, y por eso la rehabilitacion es uno de nuestros pilares",
        "Kinesioterapia respiratoria",
        "Kinesioterapia motora",
        "Kinesioterapia a embarazadas",
        "Rehabilitacion cardiopulmonar",


      ],
    },
    {
      img: img4,
      name: "Podologia clinica",
      profession: "",
      info: [
        "Tenemos profesionales encargados de atender sus nesecidades en todo tipo de patologias de los pies.",
        "Enfermeria",
        "Podologia",
      ],
    },
  ];

  return (
    <div className="container">
      <section className="team">
        <div className="team_title">
          <br />
          <h2>Nuestros servicios</h2>
          <p>Creemos que un servicio de excelencia, es la clave para responder eficazmente, en el cumplimiento dentro de lo posible las necesidades de nuestros usuarios.</p>
        </div>
        <div className="row">
          {teamMembers.map((member, index) => (
            <div key={index} className="col-lg-3 col-md-6 mb-4">
              <div className="member">
                <div className="member-img">
                  <img src={member.img} alt="img-fluid" className="img-fluid rounded-circle" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                  <div className="image_inner">

                  </div>
                </div>
                <div className="member-desc">
                  <h5>{member.name}</h5>
                  <p className="profession">{member.profession}</p>
                  {member.info.map((info, i) => (
                    <p key={i} className="info">{info}</p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
