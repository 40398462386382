// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBtadThWnRb7VXcMZ-0Py5eMIBdpq0Qlc0",
  authDomain: "centro-medico-63869.firebaseapp.com",
  projectId: "centro-medico-63869",
  storageBucket: "centro-medico-63869.appspot.com",
  messagingSenderId: "807566629937",
  appId: "1:807566629937:web:cc40dfc3c6beceb1e28910",
  measurementId: "G-J2R82HPKZG"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
