
export default function Footer() {
    return (
        <div className="container">

            <footer className="py-3 my-4">
                <div className="container text-center">
                    <p>Si le interesa sacar hora con nosotros escribanos al whatsapp</p>
                </div>
                <ul className="nav justify-content-center border-bottom pb-3 mb-3">
                    <li className="nav-item">
                        <a className="nav-link" href="https://wa.me/56939357975" title="Nuestro WhatsApp">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                                <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                            </svg>
                        </a>&nbsp;
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://wa.me/56938800510" title="WhatsApp lab Ariztia">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-flask" viewBox="0 0 16 16">
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V2h8V.5a.5.5 0 0 1 1 0V2h1a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-.5.5H15v8a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1-.5-.5V12H5v.5a.5.5 0 0 1-1 0V12H1.5a.5.5 0 0 1-.5-.5V4a.5.5 0 0 1 .5-.5H2V2a.5.5 0 0 1 .5-.5V.5A.5.5 0 0 1 3.5 0zM6.5 12h3v-1.5h-3V12z" />
                            </svg>
                        </a>&nbsp;
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://www.facebook.com/cesmed.mas.salud" title="Visitanos en Facebook">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                            </svg>
                        </a>&nbsp;
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://www.tiktok.com/@cesmed_mas_salud" title="Visitanos en Tiktok">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-tiktok" viewBox="0 0 16 16">
                                <path d="M9.5 4.342v-.791c.621.462 1.374.762 2.176.788a4.828 4.828 0 0 1-.257-1.525h-.793v1.528A3.2 3.2 0 0 1 9.5 4.342zm.6 2.82c-1.18-.064-2.161-.703-2.742-1.629v5.432a3.312 3.312 0 1 1-2.059-3.04V5.635a5.155 5.155 0 1 0 4.801 6.848 3.11 3.11 0 0 1-.656-.092V8.463c.624.015 1.218.245 1.656.617z" />
                            </svg>
                        </a>&nbsp;
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://www.instagram.com/cesmed_mas_salud/" title="Visitanos en Instagram">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.92c-.28.28-.546.453-.92.598-.281.11-.705.24-1.485.275-.843.039-1.096.047-3.231.047s-2.389-.008-3.232-.047c-.78-.035-1.203-.166-1.485-.275a2.5 2.5 0 0 1-.92-.598c-.28-.28-.453-.546-.598-.92-.11-.281-.24-.705-.275-1.485-.039-.843-.047-1.096-.047-3.231s.008-2.389.047-3.232c.035-.78.166-1.204.275-1.485a2.5 2.5 0 0 1 .598-.92c.28-.28.546-.453.92-.598.281-.11.705-.24 1.485-.275.843-.039 1.096-.047 3.231-.047zm3.658 2.163a1 1 0 1 0 0 2.002 1 1 0 0 0 0-2.002zm-3.658 1.121a4.274 4.274 0 1 0 0 8.548 4.274 4.274 0 0 0 0-8.548zm0 1.442a2.832 2.832 0 1 1 0 5.663 2.832 2.832 0 0 1 0-5.663z" />
                            </svg>
                        </a>&nbsp;
                    </li>
                </ul>
                <p className="text-center text-body-secondary">&copy; 2024 Medstudio Co</p>
            </footer>
        </div>


    )
}