import React, { useState, useEffect } from 'react';
import './css/FloatingForm.css';
import { Form, Button, FloatingLabel, Container } from 'react-bootstrap';

const FloatingForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [note, setNote] = useState('');
  const [visible, setVisible] = useState(true);

  // Función para ocultar el formulario después de 20 segundos
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 60000); // 60000 milisegundos = 60 segundos

    return () => clearTimeout(timer); // Limpiar el timer al desmontar el componente
  }, []);

  
  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneNumber = '+56939357975'; // Número de WhatsApp de destino
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      `Nombre: ${firstName} ${lastName}\nEmail: ${email}\nTeléfono: ${phone}\nNota: ${note}`
    )}`;
    window.open(whatsappURL, '_blank');
  };

  const handleMouseEnter = () => {
    setVisible(true); // Mostrar el formulario al pasar el cursor por encima
  };

  const handleMouseLeave = () => {
    // No se requiere acción al quitar el cursor
  };

  return (
    <Container className={`floating-form-container ${visible ? '' : 'hidden'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <Form onSubmit={handleSubmit} className="floating-form">
        <h2>Contáctanos</h2>
        <FloatingLabel controlId="floatingFirstName" label="Nombre" className="mb-3">
          <Form.Control
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingLastName" label="Apellidos" className="mb-3">
          <Form.Control
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingEmail" label="Email" className="mb-3">
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingPhone" label="Teléfono" className="mb-3">
          <Form.Control
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingNote" label="Nota" className="mb-3">
          <Form.Control
            as="textarea"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            required
            style={{ height: '100px' }}
          />
        </FloatingLabel>
        <Button variant="primary" type="submit">
          Enviar
        </Button>
      </Form>
    </Container>
  );
};

export default FloatingForm;
